<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.SERVICE.MACHINES") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-warning font-weight-bolder mr-2"
            @click="handleExport"
          >
            Експорт
          </button>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools :searchQuery.sync="searchQuery" hideActions>
          <template v-slot:prepend> </template>
        </SearchTools>
        <v-data-table
          class="px-6 elevation-1"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading="isLoadingServiceMachine"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          loading-text="Loading... Please wait"
          :search="searchQuery"
        >
          <template v-slot:[`item.serviceTypeString`]="{ item }">
            <ServiceMachineUpcomingItem
              v-for="sp in item.serviceTypes"
              :key="sp.id"
              :item="sp"
              :machineId="item.id"
            />
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-end">
              <router-link
                v-if="checkIfUserHasPermission(['service-update'])"
                :to="{
                  name: 'add-service-machine-rows',
                  params: { machineId: item.id },
                  query: $route.query
                }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      color="success"
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("LABELS.ADD_NEW") }}</span>
                </v-tooltip>
              </router-link>
              <router-link
                v-if="checkIfUserHasPermission(['service-update'])"
                :to="{
                  name: 'edit-service-machines',
                  params: { id: item.id },
                  query: $route.query
                }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("LABELS.EDIT") }}</span>
                </v-tooltip>
              </router-link>
            </div>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import SearchTools from "@/core/components/table/SearchTools.vue";

import { FETCH_MACHINES_FOR_SERVICE } from "@/modules/service-machines/store/service-machines.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

import ServiceMachineUpcomingItem from "../components/ServiceMachineUpcomingItem.vue";
import { GENERATE_EXPORT_SERVICE_MACHINE_URL } from "../store/service-machines.module";

export default {
  mixins: [permissionMixin],
  components: {
    SearchTools,
    ServiceMachineUpcomingItem
  },
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      searchQuery: "",
      gasStations: [],
      totalItems: 100,
      options: {
        sortBy: ["needServiceCoef"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30
      },
      filters: {},
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        { text: this.$i18n.t("FORM_LABELS.MACHINE"), value: "fullName" },
        {
          text: this.$i18n.t("FORM_LABELS.UPCOMING_SERVICE"),
          value: "serviceTypeString",
          cellClass: "d-flex align-center"
        },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  watch: {
    serviceGroupId: {
      handler() {
        this.getDataFromApi();
      }
    }
  },
  mounted() {
    if (!this.serviceGroupId) {
      this.$router.push({
        name: "list-service-machines",
        query: { serviceGroupId: 1 }
      });
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.SERVICE.MACHINES"),
        route: { name: "list-service-machines", query: this.$route.query }
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") }
    ]);
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["isLoadingServiceMachine"]),
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      return this.headers;
    },
    serviceGroupId() {
      return this.$route.query.serviceGroupId || null;
    }
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    doClear() {
      this.searchQuery = "";
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      params.serviceGroupId = this.serviceGroupId;
      let apiParams = vm.$url.transformParams(params);
      vm.$store
        .dispatch(FETCH_MACHINES_FOR_SERVICE, apiParams)
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data;
            vm.totalItems = data.data.length;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.error(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({
              name: "list-service-machines",
              query: this.$route.query
            });
          }
        });
    },
    handleExport() {
      this.$store.dispatch(
        GENERATE_EXPORT_SERVICE_MACHINE_URL,
        this.$url.transformParams({
          ...this.filters,
          query: this.searchQuery,
          serviceGroupId: this.serviceGroupId
        })
      );
    }
  }
};
</script>
