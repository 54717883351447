var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"card-custom",attrs:{"no-body":"","header-tag":"header","footer-tag":"footer"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("MENU.ITEM.SERVICE.MACHINES"))+" "),_c('i',{staticClass:"mr-2"}),_c('small',{},[_vm._v(_vm._s(_vm.$t("LABELS.LIST")))])])]),_c('div',{staticClass:"card-toolbar"},[_c('button',{staticClass:"btn btn-warning font-weight-bolder mr-2",on:{"click":_vm.handleExport}},[_vm._v(" Експорт ")])])]},proxy:true}])},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPageLoading),expression:"!isPageLoading"}]},[_c('SearchTools',{attrs:{"searchQuery":_vm.searchQuery,"hideActions":""},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return undefined},proxy:true}])}),_c('v-data-table',{staticClass:"px-6 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.isLoadingServiceMachine,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: _vm.rowsPerPageItems,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right'
        },"loading-text":"Loading... Please wait","search":_vm.searchQuery},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.serviceTypeString",fn:function(ref){
        var item = ref.item;
return _vm._l((item.serviceTypes),function(sp){return _c('ServiceMachineUpcomingItem',{key:sp.id,attrs:{"item":sp,"machineId":item.id}})})}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(_vm.checkIfUserHasPermission(['service-update']))?_c('router-link',{attrs:{"to":{
                name: 'add-service-machine-rows',
                params: { machineId: item.id },
                query: _vm.$route.query
              }}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","color":"success","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("LABELS.ADD_NEW")))])])],1):_vm._e(),(_vm.checkIfUserHasPermission(['service-update']))?_c('router-link',{attrs:{"to":{
                name: 'edit-service-machines',
                params: { id: item.id },
                query: _vm.$route.query
              }}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("LABELS.EDIT")))])])],1):_vm._e()],1)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchQuery)+"\" found no results. ")])]},proxy:true}],null,true)})],1),(_vm.isPageLoading)?_c('v-skeleton-loader',{attrs:{"loading":_vm.isPageLoading,"type":"table"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }